export default class extends Controller {
  static targets = [ "input"]

  connect() {
    this.picker = new EmojiButton()
    this.picker.on('emoji', emoji => {
      this.inputTarget.value = this.inputTarget.value + emoji.emoji
      if (this.inputTarget.dataset.guestMessageChat=='true' && this.inputTarget.value.length > 280){
        this.inputTarget.value = this.inputTarget.value.slice(0, 280);
      }
      document.querySelector('.char-counter').textContent = this.inputTarget.value.length
    })
  }

  toggle(event) {
    event.preventDefault()
    this.picker.togglePicker(event.target)
  }
}
