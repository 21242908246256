export default class extends Controller {
  static targets = ['scrollSession']

   scroller_height;

  connect(){
    const element = this.scrollSessionTarget;
    if (element) {
      // Retrieve scroller height from localStorage
      const hasAttribute = this.element.hasAttribute("data-scrolling-attribute");
      const storedHeight = localStorage.getItem('scrollerHeight');
      if (hasAttribute && storedHeight) {
        element.scrollTop = parseInt(storedHeight, 10);
      } else {
        // Otherwise, set scrollTop to 0
        element.scrollTop = 0;
      }
    }
  }

  getScrollerHeight(){
    const element = this.scrollSessionTarget
    if (element) {
      this.scroller_height = element.scrollTop;
      // Save scroller height to localStorage
      localStorage.setItem('scrollerHeight', this.scroller_height);
    }
  }
}
